import React, { useRef, useState, useEffect, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { useStore } from "../store/store";

const LightBox = () => {
  const images = useStore((state) => state.lightBoxImages);
  const selectedIndex = useStore((state) => state.lightBoxSelectedIndex);
  const openLightBox = useStore((state) => state.openLightBox);
  const closeLightBox = useStore((state) => state.closeLightBox);

  const handleNext = () => {
    if (selectedIndex === images.length - 1) {
      openLightBox(images, 0);
    } else {
      openLightBox(images, selectedIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (selectedIndex === 0) {
      openLightBox(images, images.length - 1);
    } else {
      openLightBox(images, selectedIndex - 1);
    }
  };
  return (
    <div
      className={`fixed z-50 top-0 left-0 w-screen h-screen  flex transition-all duration-300 ${
        selectedIndex !== null ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="absolute inset-0 bg-black bg-opacity-75"
        onClick={() => closeLightBox()}
      ></div>
      <div className="relative flex items-center justify-center w-full h-auto max-w-5xl mx-auto rounded">
        <button onClick={handlePrevious} className="absolute right-full focus:outline-none">
          <div className="p-4 transition-transform duration-200 transform hover:-translate-x-2">
            <svg
              className="w-5 h-5 m-10 text-white"
              viewBox="0 0 42.89 48.898"
              width="100%"
              height="100%"
            >
              <path
                d="M40.863 48.898a2.024 2.024 0 01-1.014-.271L1.014 26.206A2.029 2.029 0 010 24.45a2.026 2.026 0 011.014-1.755L39.849.273a2.029 2.029 0 012.028 0 2.03 2.03 0 011.014 1.757v44.843a2.03 2.03 0 01-1.014 1.756 2.027 2.027 0 01-1.014.269zM6.083 24.449l32.752 18.909V5.54z"
                fill="currentColor"
              />
            </svg>
          </div>
        </button>
        <AnimatePresence>
          <motion.div
            className="absolute left-0 right-0 flex flex-1"
            key={selectedIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeOut", duration: 0.3 }}
          >
            <div className="" onClick={handleNext}>
              {typeof selectedIndex === 'number' &&
                images[selectedIndex] &&
                images[selectedIndex].img && (
                  <img
                    src={`/assets/images/studio/gallery/${images[selectedIndex].img}`}
                    className="w-full max-h-screen overflow-hidden rounded-md shadow-md"
                  />
                )}
            </div>
          </motion.div>
        </AnimatePresence>
        <button onClick={handleNext} className="absolute left-full focus:outline-none">
          <div className="p-5 transition-transform duration-200 transform hover:translate-x-2">
            <svg
              className="w-5 h-5 m-10 text-white"
              viewBox="0 0 42.89 48.898"
              width="100%"
              height="100%"
            >
              <path
                d="M2.023 48.898a2.024 2.024 0 001.014-.271l38.835-22.421a2.029 2.029 0 001.014-1.756 2.026 2.026 0 00-1.014-1.755L3.037.273a2.029 2.029 0 00-2.028 0A2.03 2.03 0 00-.005 2.03v44.843a2.03 2.03 0 001.014 1.756 2.027 2.027 0 001.014.269zm34.78-24.449L4.055 43.355V5.537z"
                fill="currentColor"
              />
            </svg>
          </div>
        </button>
      </div>
      <div className="absolute top-0 right-0 px-2 py-2 lg:px-4 lg:py-3 xl:px-6 xl:py-5">
        <button
          onClick={() => closeLightBox()}
          className={`relative  flex items-center justify-center bg-gray-900 rounded-full h-12 w-12 lg:h-16 lg:w-16 text-orange focus:outline-none focus:shadow-outline-indigo z-50 group`}
        >
          <div className="relative w-6 h-6 transition-all duration-300 transform xl:w-8 xl:h-8 group-hover:rotate-90">
            <span
              className={`absolute block w-full h-px bg-white transition-all transform duration-300 rotate-45 top-1/2`}
            ></span>
            <span
              className={`absolute block w-full h-px  bg-white transition-all transform duration-300 -rotate-45 top-1/2`}
            ></span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default LightBox;
