import React, { useEffect } from "react";
import { m } from "framer-motion";
import DefaultLayout from "./Default.layout";
import SiteLayout from "./Site.layout";
// import AdminLayout from "./Admin.layout";

const layouts = {
  default: DefaultLayout,
  site: SiteLayout,
  // admin: AdminLayout
};

const isBrowser = typeof window !== "undefined";

// if (isBrowser) {
//   console.groupCollapsed(
//     '%c💀 Site Credits',
//     'display:block;padding:0.125em 1em;font-family:courier;font-size:14px;font-weight:bold;line-height:2;text-transform:uppercase;background:black;color:white;'
//   )
//   console.log(
//     '%cDesign by Nick DiMatteo \n– https://nickdimatteo.com',
//     'display:block;font-family:courier;font-size:12px;font-weight:bold;line-height:1;color:black;'
//   )
//   console.log(
//     '%cWeb Development by Nick DiMatteo \n– https://nickdimatteo.com',
//     'display:block;font-family:courier;font-size:12px;font-weight:bold;line-height:1;color:black;'
//   )
//   console.groupEnd()
// }

const duration = 0.4;
const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: 0.3,
      ease: "linear",
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration, ease: "linear", when: "beforeChildren" },
  },
};

const AnimatedLayout = ({ children }) => {
  return (
    <>
      <m.div initial="initial" animate="enter" exit="exit" variants={variants}>
        <div className="flex flex-col flex-1 bg-white">{children}</div>
      </m.div>
    </>
  );
};

const LayoutWrapper = (props) => {
  const { layout, ...layoutProps } = props.children.type;
  // to get the text value of the assigned layout of each component
  const Layout = layouts[layout];
  // if we have a registered layout render children with said layout
  if (Layout != null) {
    return (
      <AnimatedLayout>
        <Layout {...props} {...layoutProps}>
          {props.children}
        </Layout>
      </AnimatedLayout>
    );
  }
  // if not render children with fragment
  return (
    <AnimatedLayout>
      <DefaultLayout {...props} {...layoutProps}>
        {props.children}
      </DefaultLayout>
    </AnimatedLayout>
  );
};
export default LayoutWrapper;
