import React, { useState, useEffect } from "react";
import Head from "next/head"
import Router from "next/router";
import { LazyMotion, domAnimation, AnimatePresence } from "framer-motion";
import LayoutWrapper from "../layouts/LayoutWrapper";
import { init } from "../utils/sentry";

require("intersection-observer");

init();

// App css
import "../styles/app.css";

const images = [
  // "/assets/images/hero/slide-4/bg.jpg",
  // "/assets/images/hero/slide-metallica/bg-hi.jpg",
  "/assets/images/hero/history-top-cap.svg",
  "/assets/images/history/history-paint-streak.png",
];

const MyApp = ({ Component, pageProps, router, err }) => {
  const [isLoading, setIsLoading] = useState(true);

  Router.events.on("routeChangeStart", (url) => {
    console.log(`routeChangeStart: ${url}`);
    setIsLoading(true);
  });
  Router.events.on("routeChangeComplete", (url) => {
    console.log(`routeChangeComplete: ${url}`);
    setIsLoading(false);
  });

  useEffect(() => {
    (async () => {
      console.log("Image Preloader: Start");
      for (const image of images) {
        const img = new Image();
        img.src = image;
        await img.decode();
        // img is ready to use
        console.log(`url: ${image} width: ${img.width}, height: ${img.height}`);
      }
      console.log("Image Preloader: End");
      return setIsLoading(false);
    })();
  }, []);

  return (
    <div className="flex flex-col min-h-screen" data-scroll-container>
      <div
        className={`fixed flex flex-col justify-center items-center z-50 pointer-events-none inset-0 w-full h-full bg-black text-white transition-opacity duration-300 ${
          isLoading ? "opacity-100" : "opacity-0"
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute w-8 h-auto lg:w-8 brand-spinner"
          viewBox="0 0 64 64"
        >
          <path
            fill="#FFF"
            d="M32 22.9c3.95 4.12 4.88 10.5 1.9 15.67-.55.94-1.18 1.78-1.9 2.54-3.95-4.12-4.88-10.5-1.9-15.68.55-.94 1.18-1.78 1.9-2.53z"
          />
          <path
            fill="#15C4ED"
            d="M63.95 44.64L52.99 25.67l-.09-.17a12.736 12.736 0 00-4.84-4.88 13.16 13.16 0 00-6.55-1.75c-3.22 0-6.35 1.16-8.78 3.33-.25.23-.5.46-.73.71 3.95 4.12 4.88 10.5 1.9 15.67-.55.94-1.18 1.78-1.9 2.54.23.23.46.46.71.68a13.133 13.133 0 008.76 3.35h5.78c.25 0 .41-.28.29-.5L42.6 36.1c-.44-.77-1.17-1.3-2.01-1.6-.68-.24-1.27-.76-1.57-1.51-.45-1.11-.08-2.42.88-3.14 1.31-.96 3.12-.56 3.91.81.35.6.43 1.28.29 1.91-.17.77-.1 1.57.29 2.25l5.62 9.74c.21.36.59.58 1.01.58h12.64c.27 0 .42-.28.29-.5z"
          />
          <path
            fill="#FB5400"
            d="M30.1 25.43c.55-.94 1.18-1.78 1.9-2.53a12.873 12.873 0 00-2.92-2.29 13.16 13.16 0 00-6.55-1.75h-5.78c-.25 0-.41.28-.29.5l3.51 6.07 1.4 2.42c.42.73 1.06 1.37 1.87 1.59.73.21 1.38.72 1.72 1.5.49 1.12.13 2.48-.86 3.21a2.67 2.67 0 01-3.9-.81c-.32-.56-.42-1.19-.31-1.78.15-.83.14-1.67-.28-2.4l-2.15-3.72L14 19.45c-.21-.36-.59-.58-1.01-.58H.33c-.26 0-.42.28-.29.5l10.97 18.97.09.17c1.1 1.97 2.74 3.68 4.84 4.88 2.06 1.18 4.31 1.75 6.55 1.75 3.22 0 6.35-1.16 8.78-3.33.25-.23.5-.46.73-.71-3.94-4.12-4.88-10.49-1.9-15.67z"
          />
        </svg>
        <span className="w-56 h-56 spinner"></span>
      </div>
      {/* <AnimateSharedLayout> */}
      <LazyMotion features={domAnimation}>
        {isLoading && (
          <Head>
            <title>Loading...</title>
          </Head>
        )}

        <AnimatePresence
          exitBeforeEnter
          onExitComplete={() => {
            window.scrollTo(0, 0);
            document.body.classList.remove("overflow-hidden");
          }}
        >
          <LayoutWrapper {...pageProps}>
            <Component
              key={router.asPath.split("?")[0]}
              {...pageProps}
              err={err}
            />
          </LayoutWrapper>
        </AnimatePresence>
      </LazyMotion>

      {/* </AnimateSharedLayout> */}
    </div>
  );
};

export async function getStaticProps({ Component, ctx }) {
  return {
    pageProps: {
      // Call page-level getInitialProps
      ...(Component.getInitialProps
        ? await Component.getInitialProps(ctx)
        : {}),
    },
  };
}

export default MyApp;
