import Head from "next/head";
import Header from "../components/partials/Header";
import Footer from "../components/partials/Footer";

const DefaultLayout = (props) => (
  <>
    <Head>
      <title>{props.title}</title>
      <meta charSet="utf-8" />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter"
        rel="stylesheet"
      />
    </Head>
    <div className="flex flex-col flex-1 font-sans font-light bg-white pt-14 lg:pt-0">
      <Header />
      <main className="flex flex-col flex-1 overflow-hidden">
        {props.children}
      </main>
      <Footer />
    </div>
  </>
);

export default DefaultLayout;
