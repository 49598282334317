import Head from "next/head";
import Link from "next/link";
import Header from "../components/partials/Header";
import Footer from "../components/partials/Footer";

import LightBox from "../components/LightBox";

const DefaultLayout = (props) => (
  <>
    <Head>
      <title>{props.title}</title>
    </Head>
    <div className="flex flex-col flex-1 overflow-hidden font-sans font-light bg-black">
      <main className="flex flex-col flex-1 overflow-hidden">{props.children}</main>
      <Footer />
      <LightBox />
    </div>
  </>
);

export default DefaultLayout;
