import Link from "next/link";
import React from "react";


const Footer = () => {
  return (
    <footer className="relative -mt-1 text-white bg-orange" data-scroll data-scroll-section>
      <img src={require('../../images/backgrounds/footer-top-cap.svg')} loading="lazy" className="-mt-1 text-white" />
      <div className="px-6 mx-auto my-2 space-x-3 text-lg tracking-wider text-center max-w-7xl xl:text-xl">
        <Link href="/privacy-policy"><a className="transition-opacity duration-150 font-tall hover:opacity-75">Privacy Policy</a></Link>
        <Link href="/terms"><a className="transition-opacity duration-150 font-tall hover:opacity-75">Terms &amp; Conditions</a></Link>
        <Link href="/eula"><a className="transition-opacity duration-150 font-tall hover:opacity-75">Eula</a></Link>
      </div>
      <div className="px-6 mx-auto my-4 text-xs text-center max-w-7xl">
        <p>&copy; { new Date().getFullYear() } Unbroken Studios</p>
        <p>All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
